import React from "react";

const Mute = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 17">
      <path
        fill="#fff"
        d="M.5 5.5v6h4l5 5V.5l-5 5h-4zM17.143 4.805l1.232 1.232-6.158 6.158-1.232-1.232z"
      />
      <path
        fill="#fff"
        d="M12.217 4.805l-1.232 1.232 6.158 6.158 1.232-1.232z"
      />
    </svg>
  );
};

export default Mute;
