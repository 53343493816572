import styled from "styled-components";
import { toRem, breakpoints } from "utils/mixins";

export const Wrapper = styled.footer`
  width: 100%;
  position: absolute;
  z-index: 40;
  bottom: ${toRem(17)};
  left: 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media ${breakpoints.desktop} {
    .container {
      justify-content: space-between;
    }
  }
`;

export const Smedia = styled.ul`
  display: none;
  li {
    margin-right: 10px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  @media ${breakpoints.desktop} {
    display: flex;
  }
`;

export const Partners = styled.ul`
  display: flex;
  li {
    margin-left: 18px;
    img {
      max-height: 26px;
    }
  }
  @media ${breakpoints.desktop} {
    li {
      margin-left: 20px;
      img {
        max-height: 32px;
      }
    }
  }
`;
