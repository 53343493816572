import React from "react";

const Tiktok = (props) => {
  const { color } = props;
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05415 2.19648C7.05392 2.19648 6.24018 1.34258 6.24018 0.292969C6.24018 0.131055 6.11529 0 5.961 0H4.46567C4.31156 0 4.18649 0.131055 4.18649 0.292969V6.72598C4.18649 7.34297 3.70796 7.84512 3.12 7.84512C2.53203 7.84512 2.05351 7.34316 2.05351 6.72598C2.05351 6.10879 2.53185 5.60684 3.12 5.60684C3.27429 5.60684 3.39918 5.47578 3.39918 5.31387V3.74473C3.39918 3.58301 3.27429 3.45176 3.12 3.45176C1.39965 3.45176 0 4.92051 0 6.72598C0 8.53145 1.39965 10 3.12 10C4.84034 10 6.24018 8.53125 6.24018 6.72598V3.87832C6.79576 4.18926 7.41407 4.35156 8.05415 4.35156C8.20844 4.35156 8.33333 4.22051 8.33333 4.05859V2.48945C8.33333 2.32773 8.20844 2.19648 8.05415 2.19648Z"
        fill={color}
      />
    </svg>
  );
};

export default Tiktok;
