import React from "react";

const Audio = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19">
      <path
        fill="#fff"
        d="M.5 6.5v6h4l5 5v-16l-5 5h-4zm13.5 3a4.5 4.5 0 00-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM11.5.73v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z"
      />
    </svg>
  );
};

export default Audio;
