import { Link } from "gatsby";
import React from "react";
import { useCookies } from "react-cookie";
import { Wrapper } from "./Cookies.styles";

const Cookies = (props) => {
  //COOKIES
  const [cookies, setCookie] = useCookies(["hasCookies"]);
  //COOKIES

  //FUNCS
  const handleCookie = () => {
    setCookie("hasCookies", "true", { path: "/" });
  };
  //FUNCS

  return (
    <>
      {cookies.hasCookies !== "true" && (
        <Wrapper>
          <div className="container">
            <p>
              Este website utiliza{" "}
              <Link to="/politica-privacidade">cookies.</Link>
            </p>
            <button onClick={() => handleCookie()}>Fechar</button>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default Cookies;
