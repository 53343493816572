import React, { useEffect, useState } from "react";

import gsap from "gsap";
import { CookiesProvider } from "react-cookie";

import { GlobalStyles } from "theme/global";

import Header from "components/header";
import Footer from "components/footer";
import Cookies from "components/cookies";
import CreateHead from "components/createHeader";

//UTILITY
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
//UTILITY

const Layout = (props) => {
  const { location } = props;

  //STATE
  const [color, setColor] = useState(false);
  const [load, setLoad] = useState(false);
  const [dimensions, setDimension] = useState({
    height: "",
    width: ""
  });
  //STATE

  //EFFECT
  useEffect(() => {
    gsap.to("body", {
      duration: 0.2,
      delay: 0.8,
      css: { opacity: 1 }
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    setDimension({
      height: window.innerHeight,
      width: window.innerWidth
    });

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    setLoad(true);
    return () => {
      setLoad(false);
    };
  }, [location]);
  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      setColor: setColor,
      dimensions: dimensions
    });
  });
  //CHILDREN MODIFIER

  return (
    <CookiesProvider>
      <GlobalStyles />
      <CreateHead
        title={props.pageContexttitleSeo}
        description={props.pageContextdescriptionSeo}
        image={props.pageContextimageSeo}
      />
      {load && (
        <>
          <Header path={props.path} dimensions={dimensions} />
          <main>
            {childrenWithProps}
            <Footer color={color} path={props.path} />
          </main>
          <Cookies />
        </>
      )}
    </CookiesProvider>
  );
};

export default Layout;
