import { css } from "styled-components";
import { toRem, breakpoints } from "utils/mixins";

export const fontImport = css`
  @font-face {
    font-family: "Futura PT";
    src: url("/fonts/FuturaPT-Medium.woff2") format("woff2"),
      url("/fonts/FuturaPT-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Futura PT";
    src: url("/fonts/FuturaPT-Bold.woff2") format("woff2"),
      url("/fonts/FuturaPT-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Futura PT Book";
    src: url("/fonts/FuturaPT-Book.woff2") format("woff2"),
      url("/fonts/FuturaPT-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Scribble Box";
    src: url("/fonts/ScribbleBoxDEMO.woff2") format("woff2"),
      url("/fonts/ScribbleBoxDEMO.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

export const h1 = css`
  font-family: "Oswald", sans-serif;
  font-size: ${toRem(50)};
  font-weight: 900;
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(45)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(40)};
  }
`;

export const h2 = css`
  font-family: "Oswald", sans-serif;
  font-size: ${toRem(40)};
  font-weight: 900;
  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(40)};
  }
  @media (orientation: portrait) {
    font-size: ${toRem(50)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(40)};
  }
`;

export const h3 = css`
  font-family: "Oswald", sans-serif;
  font-size: ${toRem(30)};
  font-weight: 700;
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(28)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(24)};
  }
`;

export const h4 = css`
  font-family: "Oswald", sans-serif;
  font-size: ${toRem(18)};
  font-weight: 900;
  line-height: ${toRem(24)};
  @media (orientation: portrait) {
    font-size: ${toRem(14)};
    line-height: ${toRem(21)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(12)};
    line-height: ${toRem(17)};
  }
`;

export const p = css`
  font-family: "Futura PT Book";
  font-style: normal;
  font-size: ${toRem(18)};
  line-height: ${toRem(23)};
  font-weight: 500;
  color: white;
  letter-spacing: 0.2px;
  /* @media (orientation: portrait) {
    font-size: ${toRem(16)};
    line-height: ${toRem(24)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(16)};
    line-height: ${toRem(24)};
  } */
`;

export const p_alternative = css`
  font-family: "Scribble Box";
  font-weight: normal;
  font-style: normal;
`;
