import styled from "styled-components";
import { p } from "theme/typography";
import { breakpoints, toRem } from "utils/mixins";
import { orange } from "theme/colors";

export const Wrapper = styled.nav`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 49;
  right: 0;
  background-color: ${orange.darker};
  transition: 250ms;
  transform: ${(props) => (props.open ? "translateX()" : "translateX(100%)")};
  .ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    li {
      transition: 0;
      text-transform: uppercase;
      ${p}
      font-family: 'Futura PT';
      font-weight: 500;
      line-height: 200%;
      font-size: 18px;

      &.active {
        font-weight: bold;
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }

  @media (min-height: 569px) {
    .ul {
      li {
        font-size: 21px;
      }
    }
  }

  @media ${breakpoints.laptop} {
    height: 100vh;
    width: 50vw;
    .ul {
      position: relative;
      max-width: 700px;
      padding-right: ${toRem(20)};
      align-items: flex-end;
      li {
        display: block;
        margin: 0;
        padding: 0;
        font-weight: 500;
        //font-size: 24px;
        //line-height: 26px;
        //margin-bottom: 24px;
      }
    }
  }
  @media (min-width: 1439px) {
    .ul {
      li {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 24px;
      }
    }
  }
  @media (min-width: 1921px) {
    .ul {
      position: relative;
      max-width: 35vw;
    }
  }
`;

export const Smedia = styled.ul`
  position: absolute;
  bottom: ${toRem(62)};
  left: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  li {
    margin-right: 8px;
    svg {
      height: 15px;
      width: 15px;
    }
  }
  @media ${breakpoints.desktop} {
    display: none;
  }
`;

export const Policy = styled.li`
  position: absolute;
  z-index: 40;
  bottom: ${toRem(17)};
  a {
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
    text-transform: none;
  }

  @media ${breakpoints.laptop} {
    right: ${toRem(20)};
  }
`;
