import React from "react";

const Arrow = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 22">
      <path
        stroke={color ? color : "white"}
        d="M5 0v21.031M4.664 21.34l4.54-4.54M5.375 21.34L.835 16.8"
      />
    </svg>
  );
};

export default Arrow;
