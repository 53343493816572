import React from "react";

const Instagram = (props) => {
  const { color } = props;
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77778 0C1.22222 0 0 1.22222 0 2.77778V7.22222C0 8.77778 1.22222 10 2.77778 10H7.22222C8.77778 10 10 8.77778 10 7.22222V2.77778C10 1.22222 8.77778 0 7.22222 0H2.77778ZM2.77778 1.11111H7.22222C8.16667 1.11111 8.88889 1.83333 8.88889 2.77778V7.22222C8.88889 8.16667 8.16667 8.88889 7.22222 8.88889H2.77778C1.83333 8.88889 1.11111 8.16667 1.11111 7.22222V2.77778C1.11111 1.83333 1.83333 1.11111 2.77778 1.11111ZM7.77778 1.66667C7.44444 1.66667 7.22222 1.88889 7.22222 2.22222C7.22222 2.55556 7.44444 2.77778 7.77778 2.77778C8.11111 2.77778 8.33333 2.55556 8.33333 2.22222C8.33333 1.88889 8.11111 1.66667 7.77778 1.66667ZM5 2.22222C3.44444 2.22222 2.22222 3.44444 2.22222 5C2.22222 6.55556 3.44444 7.77778 5 7.77778C6.55556 7.77778 7.77778 6.55556 7.77778 5C7.77778 3.44444 6.55556 2.22222 5 2.22222ZM5 3.33333C5.94444 3.33333 6.66667 4.05556 6.66667 5C6.66667 5.94444 5.94444 6.66667 5 6.66667C4.05556 6.66667 3.33333 5.94444 3.33333 5C3.33333 4.05556 4.05556 3.33333 5 3.33333Z"
        fill={color}
      />
    </svg>
  );
};

export default Instagram;
