import { Link, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";

import { Wrapper, Logo, Toggle } from "./Header.styles";

import Nav from "./nav";

import gsap from "gsap";

const tl = gsap.timeline();

const Header = (props) => {
  //PROPS
  const { path, dimensions } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Menu {
      allWpMenu {
        edges {
          node {
            menuItems {
              nodes {
                label
                url
                path
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  const {
    allWpMenu: { edges }
  } = data;
  //DATA

  //STATES
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState("white");
  //STATES

  //DEFS
  let info = edges.filter((item) =>
    item.node.name.search("Main Menu PT") !== -1 ? item : null
  );

  let menu = info[0].node.menuItems.nodes;
  //DEFS

  const onClickOutside = (e) => {
   // console.log(!document.getElementById("nav").contains(e.target), open);
    if (!document.getElementById("nav").contains(e.target) && open) {
      closeMenu();
    }
  };

  //EFFECTS
  useEffect(() => {
    setOpen(false);
    closeMenu();
    if (
      path === "/politica-privacidade/" ||
      (path === "/12-alertas/" && dimensions.width >= 1024)
    ) {
      setColor("#398B59");
    }

    return () => {
      setOpen(false);
      setColor("white");
    };
  }, [path, dimensions]);

  useEffect(() => {
    window.addEventListener("click", onClickOutside);
    return () => {
      window.removeEventListener("click", onClickOutside);
    };
  }, [open]);
  //EFFECTS

  const openMenu = () => {
    setOpen(true);
    tl.to(".span ", {
      duration: 0.22,
      css: { top: 12 }
    })
      .to(".span--top", {
        duration: 0.22,
        rotation: 45
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        rotation: -45
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 0
      });
  };

  const closeMenu = () => {
    setOpen(false);
    tl.to(".span", {
      duration: 0.22,
      rotation: 0
    })
      .to(".span--top", {
        duration: 0.22,
        css: { top: 4 }
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        css: { top: 20 }
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 1
      });
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="wrapper">
          <Logo>
            <Link to="/">
              <img src="/images/logo_raposa.png" alt="Logo Raposa Chama" />
            </Link>
          </Logo>
          <Nav data={menu} path={path} open={open}></Nav>
        </div>

        <Toggle
          open={open}
          color={!open ? color : "white"}
          onClick={() => (open ? closeMenu() : openMenu())}
          aria-label="Botão de abertura/fecho do menu"
        >
          <div>
            <span className="span span--top"></span>
            <span className="span span--center"></span>
            <span className="span span--bottom"></span>
          </div>
        </Toggle>
      </div>
    </Wrapper>
  );
};

{
  /* <Toggle>
{open ? (
  <button onClick={() => setOpen(!open)} aria-label="Fechar menu">
    <Close color="white" />
  </button>
) : (
  <button onClick={() => setOpen(!open)} aria-label="Abrir menu">
    <Ham color={color} />
  </button>
)}
</Toggle> */
}

export default Header;
