import React from "react";

const Pdf = (props) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81645 0.5H2.78969C1.90917 0.5 1.19355 1.21562 1.19355 2.09614V8.00156H1.03798C0.68328 8.00156 0.393921 8.29091 0.393921 8.64561V12.5473C0.393921 12.902 0.68328 13.1913 1.03798 13.1913H1.19355V13.907C1.19355 14.7875 1.90917 15.5031 2.78969 15.5031H11.01C11.8905 15.5031 12.6061 14.7875 12.6061 13.907V4.27722L8.81645 0.5ZM2.0834 9.34879C2.2732 9.31767 2.53767 9.29278 2.91103 9.29278C3.28751 9.29278 3.5582 9.36434 3.73866 9.51058C3.90979 9.64748 4.02802 9.8715 4.02802 10.136C4.02802 10.4004 3.9409 10.6245 3.77911 10.7769C3.57065 10.9729 3.26262 11.0632 2.89859 11.0632C2.81769 11.0632 2.74613 11.06 2.69012 11.0507V12.0184H2.0834V9.34879ZM11.01 14.523H2.78969C2.44744 14.523 2.17052 14.2461 2.17052 13.9039V13.1882H9.83387C10.1886 13.1882 10.4779 12.902 10.4779 12.5442V8.6425C10.4779 8.2878 10.1886 7.99844 9.83387 7.99844H2.17052V2.09614C2.17052 1.757 2.44744 1.47698 2.78969 1.47698L8.4493 1.47075V3.56161C8.4493 4.17144 8.94401 4.66926 9.55696 4.66926L11.6074 4.66304L11.6291 13.9007C11.6291 14.2461 11.3522 14.523 11.01 14.523ZM4.41694 12.0059V9.34879C4.64096 9.31456 4.93344 9.29278 5.24457 9.29278C5.75795 9.29278 6.09087 9.38612 6.35223 9.58214C6.63225 9.7906 6.8096 10.1235 6.8096 10.6027C6.8096 11.1192 6.61981 11.477 6.35845 11.6979C6.0722 11.9343 5.63972 12.0464 5.11078 12.0464C4.79342 12.0464 4.5694 12.0246 4.41694 12.0059ZM8.79778 10.4315V10.9294H7.82703V12.0184H7.21408V9.31145H8.86934V9.81238H7.83014V10.4315H8.79778Z"
        fill="white"
      />
    </svg>
  );
};

export default Pdf;
