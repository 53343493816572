import React from "react";

const Youtube = (props) => {
  const { color } = props;
  return (
    <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.572 1.36625C12.4283 0.82875 12.0045 0.405 11.467 0.26125C10.492 1.30385e-07 6.58325 0 6.58325 0C6.58325 0 2.6745 1.30385e-07 1.6995 0.26125C1.162 0.405 0.738252 0.82875 0.594502 1.36625C0.333252 2.34125 0.333252 5 0.333252 5C0.333252 5 0.333252 7.65875 0.594502 8.63375C0.738252 9.17125 1.162 9.595 1.6995 9.73875C2.6745 10 6.58325 10 6.58325 10C6.58325 10 10.492 10 11.467 9.73875C12.0051 9.595 12.4283 9.17125 12.572 8.63375C12.8333 7.65875 12.8333 5 12.8333 5C12.8333 5 12.8333 2.34125 12.572 1.36625ZM5.33325 7.165V2.835L9.08325 5L5.33325 7.165Z"
      fill={color}
    />
  </svg>
  );
};

export default Youtube;
