// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-12-alertas-template-js": () => import("./../../../src/templates/12AlertasTemplate.js" /* webpackChunkName: "component---src-templates-12-alertas-template-js" */),
  "component---src-templates-404-template-js": () => import("./../../../src/templates/404Template.js" /* webpackChunkName: "component---src-templates-404-template-js" */),
  "component---src-templates-a-floresta-template-js": () => import("./../../../src/templates/AFlorestaTemplate.js" /* webpackChunkName: "component---src-templates-a-floresta-template-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/Default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-empresas-parceiras-template-js": () => import("./../../../src/templates/EmpresasParceirasTemplate.js" /* webpackChunkName: "component---src-templates-empresas-parceiras-template-js" */),
  "component---src-templates-expressoes-chama-template-js": () => import("./../../../src/templates/ExpressoesChamaTemplate.js" /* webpackChunkName: "component---src-templates-expressoes-chama-template-js" */),
  "component---src-templates-galeria-template-js": () => import("./../../../src/templates/GaleriaTemplate.js" /* webpackChunkName: "component---src-templates-galeria-template-js" */),
  "component---src-templates-homepage-template-js": () => import("./../../../src/templates/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-institucional-template-js": () => import("./../../../src/templates/InstitucionalTemplate.js" /* webpackChunkName: "component---src-templates-institucional-template-js" */),
  "component---src-templates-material-docentes-template-js": () => import("./../../../src/templates/MaterialDocentesTemplate.js" /* webpackChunkName: "component---src-templates-material-docentes-template-js" */),
  "component---src-templates-o-fogo-template-js": () => import("./../../../src/templates/OFogoTemplate.js" /* webpackChunkName: "component---src-templates-o-fogo-template-js" */),
  "component---src-templates-ola-hino-template-js": () => import("./../../../src/templates/OlaHinoTemplate.js" /* webpackChunkName: "component---src-templates-ola-hino-template-js" */),
  "component---src-templates-pergunta-banda-template-js": () => import("./../../../src/templates/PerguntaBandaTemplate.js" /* webpackChunkName: "component---src-templates-pergunta-banda-template-js" */),
  "component---src-templates-policy-template-js": () => import("./../../../src/templates/PolicyTemplate.js" /* webpackChunkName: "component---src-templates-policy-template-js" */),
  "component---src-templates-quiz-chama-template-js": () => import("./../../../src/templates/QuizChamaTemplate.js" /* webpackChunkName: "component---src-templates-quiz-chama-template-js" */)
}

