import React from "react";

const Plus = (props) => {
  const { state } = props;

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.75"
        y="0.808594"
        width="2"
        height="14"
        fill="#859D44"
        style={{ opacity: !state ? 1 : 0 }}
      />
      <rect
        x="0.75"
        y="8.80859"
        width="2"
        height="14"
        transform="rotate(-90 0.75 8.80859)"
        fill="#859D44"
      />
    </svg>
  );
};

export default Plus;
