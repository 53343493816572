import React from "react";
import { Helmet } from "react-helmet";

const CreateHead = (props) => {
  const headInfo = {
    title: props.title ? props.title : "Raposa Chama",
    description: props.description
      ? props.description
      : "Os mais novos são os embaixadores de uma causa que é de todos – Proteger Portugal de incêndios. Saiba o que fazer para proteger as pessoas, bens, animais e a Floresta. Contamos consigo nesta missão, que é de todos.",
    url: props.url ? props.url : "https://raposachama.pt",
    image: props.img ? props.img : "images/og_image.png"
  };

  return (
    <Helmet htmlAttributes={{ lang: "pt" }}>
      <title>{headInfo.title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={headInfo.description} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <meta
        name="keywords"
        content="Raposa, Chama, Fogo, Incendios, Verão, Portugal Chama, Republica Portuguesa"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />

      <meta property="og:site_name" content={headInfo.title} />

      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:image:alt" content={headInfo.title}></meta>

      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {props.children}
    </Helmet>
  );
};
export default CreateHead;
