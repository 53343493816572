import React from "react";

const Facebook = (props) => {
  const { color } = props;
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3.50002H3.32542V2.50002C3.32542 1.98403 3.36532 1.65903 4.06793 1.65903H4.95534V0.069031C4.52352 0.0220312 4.08931 -0.000968747 3.65463 3.12499e-05C2.3658 3.12499e-05 1.42518 0.828529 1.42518 2.34952V3.50002H0V5.50001L1.42518 5.49951V10H3.32542V5.49851L4.78195 5.49801L5 3.50002Z"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
