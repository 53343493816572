import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { toRem } from "utils/mixins";
import { container } from "./sizes";
import { p, h1, h2, fontImport } from "./typography";

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  ${fontImport}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  body {
    opacity: 0;
    overflow-x: hidden;
    font-size: ${toRem(20)};
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    color: black;
    -moz-osx-font-smoothing: grayscale;
  }

  .container {
    width: 100%;
    max-width: ${container.default};
    margin: 0 auto;
    position: relative;
    padding: 0 ${toRem(20)};
    &--small {
      max-width: ${container.small};
    }
    @media (min-width: 1921px) {
      max-width: unset;
      width: unset;
      margin: 0 15%;
    }
  }

  main {
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
  }

  h1 {
    ${h1}
    color: black;
    text-align: center;
    text-transform: uppercase;
    margin: ${toRem(12)};
    transition: 250ms;
  }

  h2 {
    ${h2}
    color: black;
    text-align: center;
    text-transform: uppercase;
    margin: ${toRem(12)};
    transition: 250ms;
  }

  p {
    ${p}
    cursor: default;
  }

  span {
    cursor: default;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 250ms;
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
  }

  address {
    font-style: normal;
  }

  p {
    margin-bottom: 1em;
  }

  .slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */
`;
