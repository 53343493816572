import { useStaticQuery } from "gatsby";
import React from "react";

import { Facebook, Tiktok, Youtube, Instagram } from "components/icons";

import { Wrapper, Smedia, Partners } from "./Footer.styles";

const Footer = (props) => {
  //PROPS
  const { color, path } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Footer {
      page: wpPage(id: { eq: "cG9zdDoxODE=" }) {
        footer {
          socialLinks {
            link
            socialMedia
          }
        }
      }
    }
  `);
  const {
    page: {
      footer: { socialLinks }
    }
  } = data;
  //DATA

  return (
    <Wrapper>
      <div className="container">
        <Smedia>
          {socialLinks.map((elem, index) => {
            return (
              <li key={index}>
                <a
                  rel="noreferrer"
                  href={elem.link}
                  target="_blank"
                  aria-label={elem.socialMedia}
                >
                  {elem.socialMedia === "instagram" && (
                    <Instagram
                      color={
                        path === "/politica-privacidade/" || path === "/parceiros/" ? "black" : "white"
                      }
                    />
                  )}
                  {elem.socialMedia === "facebook" && (
                    <Facebook
                      color={
                        path === "/politica-privacidade/" || path === "/parceiros/" ? "black" : "white"
                      }
                    />
                  )}
                  {elem.socialMedia === "youtube" && (
                    <Youtube
                      color={
                        path === "/politica-privacidade/" || path === "/parceiros/" ? "black" : "white"
                      }
                    />
                  )}
                  {elem.socialMedia === "tiktok" && (
                    <Tiktok
                      color={
                        path === "/politica-privacidade/" || path === "/parceiros/" ? "black" : "white"
                      }
                    />
                  )}
                </a>
              </li>
            );
          })}
        </Smedia>
        <Partners>
          <li>
            <a
              rel="noreferrer"
              href="https://portugalchama.pt"
              target="_blank"
              aria-label="Logo Portugal Chama"
            >
              <img
                alt="Logo Portugal Chama"
                src={
                  color
                    ? "/images/Portugal_Chama_black.png"
                    : "/images/Portugal_Chama.png"
                }
              />
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              href="https://www.portugal.gov.pt"
              target="_blank"
              aria-label="Logo Republica Portuguesa"
            >
              <img
                alt="Logo Republica Portuguesa"
                src={
                  color
                    ? "/images/logo_rp_largo.png"
                    : "/images/logo_rp_largo_white.png"
                }
              />
            </a>
          </li>
        </Partners>
      </div>
    </Wrapper>
  );
};

export default Footer;
