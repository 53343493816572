import React from "react";

const Pause = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 15">
      <path fill="#fff" d="M.5 14.5h4V.5h-4v14zm8-14v14h4V.5h-4z" />
    </svg>
  );
};

export default Pause;
