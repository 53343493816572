import styled from "styled-components";
import { toRem, breakpoints } from "utils/mixins";
import { orange, white } from "../../theme/colors";

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 50;
  .container {
    padding-top: ${toRem(15)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wrapper {
      display: flex;
      align-items: center;
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  z-index: 50;
  img {
    height: ${toRem(110)};
    width: auto;
    object-fit: contain;
  }
  @media (min-width: 767px) {
    img {
      height: ${toRem(130)};
    }
  }
  @media (min-width: 1300px) {
    img {
      height: ${toRem(150)};
    }
  }

  @media (min-width: 1400px) {
    img {
      height: ${toRem(170)};
    }
  }
`;

export const Toggle = styled.button`
  position: relative;

  margin-right: 15px;
  display: block;
  background: red;
  z-index: 201;

  background-color: rgba(231, 118, 44, 0.7);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  div {
    display: block;
    width: 26px;
    height: 26px;
    position: relative;

    .span {
      top: 12px;
      background-color: ${(props) => (!props.open ? "" : white.default)};
      width: 26px;
      height: 2px;
      display: block;
      z-index: 30;
      position: absolute;
      overflow: hidden;
      cursor: pointer;

      &::before {
        z-index: 2;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${white.default};
        width: 26px;
        height: 2px;
        transition: 400ms;
        transform: translateX(-200%);
      }
      &::after {
        z-index: 2;
        content: "";
        position: absolute;
        left: 0;
        transition: 400ms;
        top: 0;
        background-color: ${white.default};
        width: 26px;
        height: 2px;
        transform: translateX(0%);
      }
      &--top {
        position: absolute;
        top: 4px;
        &::before {
          transition-delay: 0ms !important;
        }
        &::after {
          transition-delay: 0ms !important;
        }
      }
      &--center {
        &::before {
          transition-delay: 200ms !important;
        }
        &::after {
          transition-delay: 200ms !important;
        }
      }
      &--bottom {
        position: absolute;
        top: 20px;
        &::before {
          transition-delay: 400ms;
        }
        &::after {
          transition-delay: 400ms;
        }
      }
    }

    &:hover {
      .span {
        &::before {
          transform: ${(props) => (!props.open ? "translateX(0)" : "")};
        }
        &::after {
          transform: ${(props) => (!props.open ? "translateX(200%)" : "")};
        }
      }
    }
  }
`;
