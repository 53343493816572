export const white = {
  default: "rgb(255, 255, 255)"
};

export const black = {
  default: "rgb(0, 0, 0)"
};

export const green = {
  default: "#398B59"
};

export const pink = {
  default: "#d17fa7"
};

export const orange = {
  default: "#FA8C51",
  darker: "#E7762C"
};

export const blue = {
  default: "#3B458E",
  light: "rgb(97, 204, 255)"
};
