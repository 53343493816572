import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  background: rgba(231, 118, 44, 0.8);
  z-index: 51;
  bottom: 0;
  left: 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a {
    text-decoration: underline;
  }
  button {
    font-family: "Futura PT";
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: white;
  }
`;
