import React from "react";

const Play = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 16">
      <path fill="#fff" d="M.607.5v15L12.393 8 .607.5z" />
    </svg>
  );
};

export default Play;
