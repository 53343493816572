import React, { useEffect, useState } from "react";
import { Link, useStaticQuery } from "gatsby";

import { Wrapper, Smedia, Policy } from "./Nav.styles";
import { Facebook, Tiktok, Youtube, Instagram } from "components/icons";

const Nav = (props) => {
  //PROPS
  const { data, path, open } = props;
  //PROPS

  //DATA
  const info = useStaticQuery(graphql`
    query Nav {
      page: wpPage(id: { eq: "cG9zdDoxODE=" }) {
        footer {
          socialLinks {
            link
            socialMedia
          }
        }
      }
    }
  `);
  const {
    page: {
      footer: { socialLinks }
    }
  } = info;
  //DATA

  const [tPath, setPath] = useState("");

  useEffect(() => {
    setPath(path);
  }, [path]);

  return (
    <Wrapper open={open} id="nav">
      <ul className="ul">
        {data.map((item, index) => {
          // if (index + 1 !== data.length) {
          // console.log(tPath, item.path);
          return (
            <li
              key={index}
              className={
                tPath === item.path ||
                (item.path === "/institucional/" &&
                  (tPath === "/ola/" ||
                    tPath === "/material-docentes/" ||
                    tPath === "/outras-publicacoes/" ||
                    tPath === "/galeria-de-imagens/" ||
                    tPath === "/desenhos-para-imprimir-e-colorir/" ||
                    tPath === "/parceiros/"))
                  ? "active"
                  : item.path === "#"
                  ? "disabled"
                  : ""
              }
            >
              {item.path === "#" ? (
                item.label
              ) : (
                <Link to={item.path} title={item.label}>
                  {item.label}
                </Link>
              )}
            </li>
          );
          // } else {
          //   return (
          //     <li key={index}>
          //       <a href={item.path}>{item.label}</a>
          //     </li>
          //   );
          // }
        })}
        <Policy>
          <Link to="/politica-privacidade">
            Política de privacidade e uso de cookies
          </Link>
        </Policy>
      </ul>
      <Smedia>
        {socialLinks.map((elem, index) => {
          return (
            <li key={index}>
              <a
                href={elem.link}
                rel="noreferrer"
                target="_blank"
                aria-label={elem.socialMedia}
              >
                {elem.socialMedia === "instagram" && (
                  <Instagram
                    color={
                      path === "/politica-privacidade/" ? "black" : "white"
                    }
                  />
                )}
                {elem.socialMedia === "facebook" && (
                  <Facebook
                    color={
                      path === "/politica-privacidade/" ? "black" : "white"
                    }
                  />
                )}
                {elem.socialMedia === "youtube" && (
                  <Youtube
                    color={
                      path === "/politica-privacidade/" ? "black" : "white"
                    }
                  />
                )}
                {elem.socialMedia === "tiktok" && (
                  <Tiktok
                    color={
                      path === "/politica-privacidade/" ? "black" : "white"
                    }
                  />
                )}
              </a>
            </li>
          );
        })}
      </Smedia>
    </Wrapper>
  );
};

export default Nav;
